const { NETWORK_ENV } = process.env

// eslint-disable-next-line import/no-mutable-exports
let pools = {}

switch (NETWORK_ENV) {
  case 'kovan':
    pools = require('./kovan').default
    break
  case 'mainnet':
    pools = require('./mainnet').default
    break
  case 'mainnet-fork':
    pools = require('./mainnet-fork').default
    break
  default:
    pools = require('./kovan').default
}

export default pools
