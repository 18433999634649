import localeAntd from 'antd/es/locale/zh_CN'

const messages = {
  // General
  'common.connectWallet': '连接钱包',
  'common.yourBalance': '您的余额',
  'common.balance': '余额',
  'common.step': '步骤',
  'common.rewardsHint1': '您有 ',
  'common.rewardsHint2': '质押奖励',
  'common.warning': '警告',
  'common.guide': '引导',
  'common.applyNow': '现在申请',
  'common.aldPrice': 'ALD 价格',
  'common.all': '全部',
  'common.myPools': '我锁仓的',
  'common.myPoolsVault': '我存入的',
  'common.earned': '已赚取',
  'common.reward': '收益',
  'common.staked': '已质押',
  'common.deposited': '已存入',
  'common.readMore': '了解更多',
  'common.learnMore': '了解更多',
  'common.common': '步骤',
  // Action
  'action.back': '返回',
  'action.join': '加入 Aladdin DAO',
  'action.deposit': '存入',
  'action.withdraw': '取出',
  'action.claim': '领取',
  'action.claimAll': '领取所有',
  'action.unstake': '取出',
  'action.stake': '质押',
  'action.staking': '质押中',
  'action.farm': 'Farm',
  'action.harvest': 'Harvest',
  'action.strategy.harvest': 'strategy harvest',
  'action.unstakeClaim': '取出（含奖励）',
  'action.approve': '授权',
  'action.approving': '授权中',
  'action.addLiquidity': '增加流动性',
  'action.stakeLp': '质押 LP',
  'action.logout': '登出',
  'action.quit': '退出',
  'action.notQuit': '不退出',
  'action.ok': '确定',

  // Form
  'form.max': '全部',
  'form.amount': '金额',
  'form.shareToPurchase': '最多购买份额',
  'form.wantToGet': '我希望得到',
  'form.output': '产出',
  'form.isWhitelisted': '是否被列入白名单',
  'form.shareCap': '您的额度/额度上限',
  'form.accumulatedShares': '累积额度',
  'form.alddaoCap': 'ALDDAO 的上限',
  'form.alddaoInTotal': '总计 ALDDAO',
  'form.alddaoInTotalHint': '购买的 ALDDAO 总量',
  'form.aldVoteBalance': 'ALDVOTE 余额',
  'form.estimateOutput': '将会收到',
  'form.currentlyStaked': '目前已质押',
  'form.rewardsAvailable': '可领取的奖励',
  'form.performanceFee': '手续费',
  'form.performanceFeeText': '收益的10%',
  // Table
  'table.tvl': 'TVL（总锁仓量）',
  'table.vault_token_symbol': 'Vault 通证符号',
  'table.vault_token_decimal': 'Vault 通证精度',
  'table.vault_contract_address': 'Vault 合约地址',
  'table.stake_contract_symbol': '质押通证符号',
  'table.stake_contract_address': '质押合约地址',
  'table.tokenPrice': '通证价格',
  'table.apy': 'APY（年化收益）',
  'table.liquidity': '流动性',
  'table.reward': '奖励',
  'table.pool': '池子',
  'table.rewardToken': '奖励通证',
  'table.targetTvl': '目标 TVL',
  'table.currentTvl': '当前的 TVL',
  'table.kpiRewards': 'KPI 和奖励',
  'table.miners': '矿工',
  'table.dao': 'DAO 成员',
  'table.dueDate': '到期日',
  'table.tvlRatio': 'TVL 比率',
  'table.minersRewards': '矿工奖励',
  'table.myReward': '我的奖励',
  'table.vault_go_to_stake': '去质押',

  // Navigation
  'nav.vault': 'Vault',
  'nav.staking': '质押',
  'nav.mining': '挖矿',
  'nav.membership': 'Dao 成员',
  'nav.optionReward': '期权奖励',

  // mining UI
  'mining.banner': '与聪明的 DeFi 高手一同挖矿',
  'mining.all': '全部',
  'mining.vault': 'Vault',
  'mining.staking': '质押',
  'mining.liquidity': '流动性挖矿',
  'membership.title': '委员会成员',
  'membership.subtitle':
    'AladdinDAO 创世成员通过存入 USDC，可以获得份额通证（ALDDAO）和提案权通证（ALDVOTE），ALDDAO Stake 后获得 ALD 通证。ALDVOTE 不能转让，只用于 DAO 的治理决策。合约地址：',
  'membership.limitHint': '目前仅允许白名单地址进行存款。请联系我们的社区管理员。',
  'membership.clickHere': '点击这里',
  'membership.whitelistAddress': '白名单地址',
  'membership.howTo': '加入我们',
  'membership.familyPurpose': '我们的目标',
  'membership.familyMembers': '我们的成员',

  // staking UI
  'staking.title': '质押 Vault 凭证通证',
  'staking.subtitle': '质押 Vault 凭证通证获得 AladdinDAO 治理通证',
  'staking.stakingTVL': '质押 TVL',
  'staking.availableToClaim': '可领取的 ALD',
  'staking.aldTotalSupply': 'ALD 总供应量',
  'staking.aldBonusPerBlock': '每个区块 ALD 奖励数量',

  // staking hint
  'stakingHint.getMore': '获得更多',
  'stakingHint.achieveDouble': '实现双挖收益',
  'stakingHint.goLiquidityPool': '去查看',
  'stakingHint.goEarn': '去赚取 ALD',
  'stakingHint.aldStaking': '获得 Aladdin 质押',
  'stakingHint.learnMore': '了解更多 Aladdin',
  'stakingHint.whenStake': '质押特定的通证，可以获得 ALD 作为额外奖励',
  'stakingHint.stakeEarn': '质押你的 {tokenSymbol} 去赚取 ALD',
  // boule plus
  'boule.bannerTitle': 'Boule Plus',
  'boule.bannerSubtitle': '没能成为 Boule Member？想证明自己？快来成为 Boule Plus 吧',
  'boule.what.title': 'Boule Plus 能得到什么？',
  'boule.what.1': '如同 Boule Member，参与每月的投票',
  'boule.what.2': '如同 Boule Member，按规则从投票的 Vault 中获得奖励',
  'boule.what.3': 'Boule Plus 的奖励来自额外的奖池',
  'boule.what.4': '投票奖励规则与 Boule Member 一致',
  'boule.step.1': '你需要加入我们的社区并通过小测验。点击：',
  'boule.step.2.1': '锁仓 ',
  'boule.step.2.2': '成为 Boule Plus',
  'boule.step.3': '努力调研并在每月投票中选择你认为有潜力的 Vault',
  'boule.step.4': '从收益表现好的 Vault 中获得奖励',
  'boule.rewardTitle': '总奖池',
  'boule.youAre.title': '你是 Boule Plus',
  'boule.youAre.1': '每个月有 4 枚选票',
  'boule.youAre.2': '努力调研并及时投票',
  'boule.votingEntrance': '投票入口',
  'boule.hold': '持有 ALDPlus 才能获得 Vault 奖励',
  'boule.unstakeAndLose': '解锁并放弃奖励',
  'boule.become.title': '成为 Boule Plus',
  'boule.become.hint1': '你需要锁仓 ',
  'boule.become.hint2': ' 才能成为 Boule Plus',
  'boule.clickToStake': '点击锁仓',
  'boule.stake.hint1': '你将会收到 ALDPlus 作为凭证',
  'boule.stake.hint2': '你可以随时解锁 ALD 并退出 Boule Plus',
  'confirmUnstake.title': '确定解锁吗？',
  'confirmUnstake.desc': '解锁将退出 Boule Plus 并且你将不能从已投票的 Vault 中获得奖励',
  'notQualified.title': '成为 Boule Plus',
  'notQualified.desc.1': '你需要先通过小测验',
  'notQualified.desc.2': '如果已经通过了，请确定地址是正确的',
  // option reward UI
  'optionReward.title': '奖励',
  'optionReward.subtitle':
    'Aladdin 将 15% 的通证用于激励 TVL 增长，TVL 每增加 1 亿美金，奖励释放 0.75% 的 ALD，最大奖励到 20 亿美金 TVL，激励有效期一个月，从创世挖矿开始，一个月后将不在释放奖励。奖励将分发给所有质押 ALD 的用户，一年内匀速释放完成。 质押 ALD 的用户，有机会获得平台上架项目 TVL 增长带来的期权奖励。',
  'optionReward.aladdinStaking': 'Aladdin 质押',

  // staking reward UI
  'stakingReward.title': 'Aladdin 挖矿',

  'stakingReward.subtitle': '通证持有者可以将 ALD 质押到 Aladdin 奖励池中，并在达到目标 TVL 后从项目奖励中获取奖励。',

  'stakingReward.tableInfo': '',

  // vault UI
  'vault.tableInfo': '有关此 Vault 更多的信息',
  'staking.tableInfo': '有关质押池更多的信息',

  // liquidity UI
  'liquidity.title': '流动性',
  'liquidity.subtitle': '流动性描述',
  'liquidity.tableInfo': '关于项目',

  // banners
  vaultBanner1: '实现双挖收益 : {rewardSymbol}+ALD。',
  vaultBanner2: `第一步：在 {poolName} 平台中存入 {deposit}，获得 {stakeSymbol}。`,
  vaultBanner3: `第二步：将 {stakeSymbol} 存入 Aladdin 的 Vault 中，赚取 {rewardSymbol} 收益，并获得 {vaultSymbol} 作为 Vault 凭证。`,
  vaultBanner4: `第三步：将 {vaultSymbol} 存入 Aladdin 的质押池中，可获得额外的 ALD 通证奖励。`,
  stakingBanner: `质押 Vault 凭证通证，在 Vault 中获得收益的同时，还可以获得额外的 ALD 收益。如果没有 {stakeTokenSymbol}, 请先到 Vault 中存入 {stakeToken}`,

  // vaultBanner1: '在 {poolName} 平台中存入 {deposit}，获得 {stakeSymbol}。',
  // vaultBanner2: `用户将 {stakeSymbol} 存入 Aladdin 的 Vault 中，Aladdin 协议会自动把 {stakeSymbol} 存入 {poolName} 平台，赚取 {rewardSymbol} 和利息，同时用户还可获得 {vaultSymbol}。`,
  // vaultBanner3: `将 {vaultSymbol} 存入 Aladdin 的质押池中，可获得 ALD 通证奖励。`,
  // vaultBanner4: `双挖收益 : {rewardSymbol}+ALD。`,
  // stakingBanner: `Vault 中的资产（例如Comp,CRV,Sushi等）存入 StakingPool 后，在 Vault 中获得原资产收益的同时，还可以获得 ALD 收益。如果没有 {stakeTokenSymbol}, 请先到 Vault 中存入{stakeToken}`,

  // lbp pagge
  'lbp.title': 'Alddin Token 首次启动',
  'lbp.subtitle': '北京时间：2021 年 4 月 XX 日 XX:00:00-XX:00:00 - 2021 年 4 月 XX 日 XX:00:00-XX:00:00)',
  'lbp.banner1': '交易对和初始金额',
  'lbp.banner2': '起始比率（ALD：USDC）',
  'lbp.banner3': '结束比率（ALD：USDC）',
  'lbp.banner4': '起始价',
  'lbp.aldBalance': 'ALD 余额',
  'lbp.usdcBalance': 'USDC 余额',
  'lbp.aldWeight': 'ALD 权重',
  'lbp.usdcWeight': 'USDC 权重',
  'lbp.aldSold': 'ALD 已售',
  'lbp.fundRaised': '已募集资金',
  'lbp.spotPrice': '现货价格',
  'lbp.terminal': '初始定价',
  'lbp.swapOnBalance': 'Swap On Balancer',
  'lbp.warning1': '请不要急于购买，否则您将被拒收。',
  'lbp.warning2': '注意您的 Gas 手续费和交易滑点设置。',
  'lbp.warning3': '在 LBP 结束之前，请勿在 Uniswap 或 Balancer 上创建新池（请注意避免永久损失！）。',
  'lbp.warning4': '提防欺诈，仅使用 Alddin 官方公布的 ALD 地址。',
}

export default {
  locale: 'zh-CN',
  localeAntd,
  messages,
}
