import React from 'react'
import { message } from 'antd'
import { ReactComponent as IconTwitter } from 'assets/icons/twitter.svg'
import { ReactComponent as IconMedium } from 'assets/icons/Medium.svg'
import { ReactComponent as IconGitbook } from 'assets/icons/GitBook.svg'
import { ReactComponent as IconGithub } from 'assets/icons/GitHub.svg'
import { ReactComponent as IconDiscord } from 'assets/icons/Discord.svg'
import { ReactComponent as IconTelegram } from 'assets/icons/telegram.svg'
import config from 'config'
import style from './style.module.scss'

const socialLinks = [
  {
    key: 'twitter',
    icon: <IconTwitter className={style.socialIcons} />,
    link: 'https://twitter.com/AladdinDAO',
  },
  {
    key: 'medium',
    icon: <IconMedium className={style.socialIcons} />,
    link: 'https://aladdindao.medium.com',
  },
  {
    key: 'gitbook',
    icon: <IconGitbook className={style.socialIcons} />,
    link: 'https://docs.aladdin.club/',
  },
  {
    key: 'github',
    icon: <IconGithub className={style.socialIcons} />,
    link: 'https://github.com/AladdinDAO/',
  },
  {
    key: 'discord',
    icon: <IconDiscord className={style.socialIcons} />,
    link: 'https://discord.gg/Ymwar7c6V8',
  },
  {
    key: 'telegramm',
    icon: <IconTelegram className={style.socialIcons} />,
    link: 'https://t.me/aladdin_dao',
  },
]

const Footer = () => {
  const addToWallet = async () => {
    const tokenAddress = config.contracts.nativeToken
    const tokenDecimals = 18
    const tokenSymbol = 'ALD'
    const tokenImage = 'https://aladdin.club/square.png'

    try {
      const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      })

      if (tokenAdded) {
        message.success('ALD Token Added')
      } else {
        message.error('Failed to add ALD to your wallet')
      }
    } catch (error) {
      message.error('Failed to add ALD to your wallet')
    }
  }
  return (
    <div className={style.footer}>
      <div className="container">
        <div className={style.footerInner}>
          <div>
            <div className={style.footerTitle}>Links</div>
            <ul className={style.links}>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://docs.aladdin.club/">
                  Docs
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://forms.gle/3aRbtbGT4DZ6WYd67">
                  Listing Application
                </a>
              </li>
              {/* <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://t.me/Aladdindaoannouncement"
                >
                  Announcement
                </a>
              </li> */}
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://docs.aladdin.club/governance/security-bounty">
                  Bug Bounty
                </a>
              </li>
              <li>
                <a onClick={addToWallet} role="button">
                  Add ALD to Wallet
                </a>
              </li>
            </ul>
          </div>
          <div className={style.socials}>
            {socialLinks.map(item => (
              <a
                key={item.key}
                target="_blank"
                rel="noopener noreferrer"
                alt={item.key}
                href={item.link}
                className={style.socialLink}
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
        <div className={style.copyright}>
          <div className={style.risk}>This project is in beta. Use at your own risk.</div>
          Copyright © 2021 Aladdin ENS:{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://etherscan.io/address/aladdindao.eth">
            AladdinDAO.eth
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
