import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import TopBar from 'components/cleanui/layout/TopBar'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  // menuColor: settings.menuColor,
  role: user.role,
  theme: settings.theme,
})

const MenuTop = ({
  menuData = [],
  location: { pathname },

  // menuColor,
  // logo,
  role,
  theme,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, disabled } = item
      if (item.hide) {
        return null
      }
      if (item.category) {
        return null
      }
      if (item.url) {
        return (
          <Menu.Item className="navigation-item" key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {/* {icon && <span className={`${icon} ${style.icon}`} />} */}
                <span className={style.title}>{title}</span>
                {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                {/* {icon && <span className={`${icon} ${style.icon}`} />} */}
                <span className={style.title}>{title}</span>
                {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {/* {icon && <span className={`${icon} ${style.icon}`} />} */}
          <span className={style.title}>{title}</span>
          {/* {count && <span className="badge badge-success ml-2">{count}</span>} */}
        </Menu.Item>
      )
    }
    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {/* {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />} */}
              <span className={style.title}>{menuItem.title}</span>
              {/* {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>} */}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })
    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {/* {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />} */}
            <span className={style.title}>{menuItem.title}</span>
            {/* {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>} */}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  return (
    <div>
      <div
        className={classNames(`${style.menu}`, {
          [style.white]: theme === 'default',
          [style.dark]: theme === 'dark',
        })}
      >
        <div className={classNames(`${style.topLine}`, 'container')}>
          <Link to="/" className={style.logoLink}>
            <img
              className={style.logo}
              src={theme === 'dark' ? 'resources/images/logo-dark.svg' : 'resources/images/logo-light.svg'}
              alt="Aladdin Logo"
            />
          </Link>

          <TopBar />
        </div>
      </div>
      <div className={style.navigation}>
        <div className="header-menu">
          <div className="container">
            <Menu
              className="header-menu-content"
              onClick={handleClick}
              selectedKeys={selectedKeys}
              mode="horizontal"
              style={{ justifyContent: 'flex-start' }}
            >
              {generateMenuItems()}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuTop))
