import localeAntd from 'antd/es/locale/en_US'

const messages = {
  // General
  'common.connectWallet': 'Connect Wallet',
  'common.yourBalance': 'Your Balance',
  'common.balance': 'Balance',
  'common.step': 'Step',
  'common.rewardsHint1': 'You have ',
  'common.rewardsHint2': ' Staking rewards',
  'common.warning': 'Warning',
  'common.guide': 'GUIDE',
  'common.applyNow': 'Apply Now',
  'common.aldPrice': 'ALD Price',
  'common.all': 'All',
  'common.myPools': 'My Pools',
  'common.myPoolsVault': 'My Pools',
  'common.earned': 'Earned',
  'common.reward': 'REWARD',
  'common.staked': 'Staked',
  'common.deposited': 'Deposited',
  'common.readMore': 'Read More',
  'common.learnMore': 'Learn More',
  'common.common': 'Step',
  // Action
  'action.back': 'Back',
  'action.join': 'Join Aladdin DAO',
  'action.deposit': 'Deposit',
  'action.withdraw': 'Withdraw',
  'action.claim': 'Claim',
  'action.claimAll': 'Claim All',
  'action.unstake': 'Unstake',
  'action.stake': 'Stake',
  'action.staking': 'Staking',
  'action.farm': 'Farm',
  'action.harvest': 'Harvest',
  'action.strategy.harvest': 'Harvest Strategy',
  'action.unstakeClaim': 'Unstake & Claim',
  'action.approve': 'Approve',
  'action.approving': 'Approving',
  'action.addLiquidity': 'Add Liquidity',
  'action.stakeLp': 'Stake LP',
  'action.logout': 'Disconnect',
  'action.quit': 'Confirm',
  'action.notQuit': 'Cancel',
  'action.ok': 'OK',

  // Form
  'form.max': 'MAX',
  'form.amount': 'Amount',
  'form.shareToPurchase': 'Share',
  'form.wantToGet': 'I want to get',
  'form.output': 'Output',
  'form.isWhitelisted': 'Is Whitelisted',
  'form.shareCap': 'Your Share / Share Cap',
  'form.accumulatedShares': 'Accumulated Shares',
  'form.alddaoCap': 'ALDDAO CAP',
  'form.alddaoInTotal': 'ALDDAO In Total',
  'form.alddaoInTotalHint': 'Amount of ALDDAO bought',
  'form.aldVoteBalance': 'ALDVOTE Balance',
  'form.estimateOutput': 'Estimate Output',
  'form.currentlyStaked': 'Currently Staked',
  'form.rewardsAvailable': 'Rewards Available',
  'form.performanceFee': 'Performance Fee',
  'form.performanceFeeText': '10% of Rewards',
  // Table
  'table.tvl': 'TVL(Total Value Locked)',
  'table.vault_token_symbol': 'Vault Token Symbol',
  'table.vault_token_decimal': 'Vault Token Decimal',
  'table.vault_contract_address': 'Vault Contract Address',
  'table.stake_contract_symbol': 'Stake Token Symbol',
  'table.stake_contract_address': 'Stake Token Address',
  'table.tokenPrice': 'Token Price',
  'table.apy': 'APY(Annual Percentage Yield)',
  'table.liquidity': 'Liquidity',
  'table.reward': 'Reward',
  'table.pool': 'Pool',
  'table.rewardToken': 'RewardToken',
  'table.targetTvl': 'Target TVL',
  'table.currentTvl': 'Current TVL',
  'table.kpiRewards': 'KPI & Rewards',
  'table.miners': 'Miners',
  'table.dao': 'DAO',
  'table.dueDate': 'Due Date',
  'table.tvlRatio': 'TVL-Ratio',
  'table.minersRewards': "Miners' Rewards",
  'table.myReward': 'My Reward',
  'table.vault_go_to_stake': 'Go to Stake',

  // Nav
  'nav.vault': 'Vaults',
  'nav.staking': 'Staking',
  'nav.membership': 'DAO',
  'nav.optionReward': 'Option Rewards',

  // mining UI
  'mining.banner': 'Farm With DeFi Big Brains',
  'mining.all': 'All',
  'mining.vault': 'Vault',
  'mining.staking': 'Staking',
  'mining.liquidity': 'Liquidity',
  'membership.title': 'DAO',
  'membership.subtitle':
    'Committee members can obtain ALDDAO and ALDVOTE by depositing USDC. ALD tokens will be distributed by staking ALDDAO. Please note, ALDVOTE cannot be transferred and is only used for Aladdin governance. Contract Address: ',
  'membership.limitHint': 'Only whitelist address is permitted to deposit. Please contact community administrator.',
  'membership.clickHere': 'Click here',
  'membership.whitelistAddress': 'Whitelist addresses',
  'membership.howTo': 'Join Our Family',
  'membership.familyPurpose': 'Family Purpose',
  'membership.familyMembers': 'Family Members',

  // staking UI
  'staking.title': 'Staking',
  'staking.subtitle': 'Stake vault tokens and get Aladdin DAO Token',
  'staking.stakingTVL': 'Staking TVL',
  'staking.availableToClaim': 'ALD available to claim',
  'staking.aldTotalSupply': 'ALD Total Supply',
  'staking.aldBonusPerBlock': 'ALD Bonus Per Block',

  // staking hint
  'stakingHint.getMore': 'Get more',
  'stakingHint.achieveDouble': 'Get double mining rewards',
  'stakingHint.goLiquidityPool': 'Go to the Liquidity Pool',
  'stakingHint.goEarn': 'Go To Earn ALD',
  'stakingHint.aldStaking': 'Aladdin Staking',
  'stakingHint.learnMore': 'Learn more about Aladdin',
  'stakingHint.whenStake': 'When you stake the required tokens, you will get ALD as rewards',
  'stakingHint.stakeEarn': 'Stake your {tokenSymbol} tokens to earn ALD',

  // boule plus
  'boule.bannerTitle': 'Boule Plus',
  'boule.bannerSubtitle':
    'If you missed Boule Member election and want to prove youself, Boule Plus is the right choice for you!',
  'boule.what.title': 'What is the Boule Plus role?',
  'boule.what.1': 'Boule Plus members participate in monthly vault votings just like Boule Members.',
  'boule.what.2': 'Boule Plus receive reward from vault votings just like Boule Members.',
  'boule.what.3': 'Boule Plus rewards come from a special reward pool.',
  'boule.what.4': 'Voting and reward rules are consistent with Boule members.',
  'boule.step.1': 'Join our community and pass our DeFi Quiz. Check out ',
  'boule.step.2.1': 'Stake ',
  'boule.step.2.2': ' to become Boule Plus.',
  'boule.step.3': 'Choose the vault that you believe will perform well in the monthly voting.',
  'boule.step.4': 'Receive ALD rewards from successful votings.',
  'boule.rewardTitle': 'Total Reward Pool',
  'boule.youAre.title': 'You are Boule Plus',
  'boule.youAre.1': '4 vote opportunities per month.',
  'boule.youAre.2': 'Research hard and vote on time.',
  'boule.votingEntrance': 'Voting Entrance',
  'boule.hold': 'Hold ALDPlus to get rewards.',
  'boule.unstakeAndLose': 'Unstake and Lose Rewards',
  'boule.become.title': 'Become Boule Plus',
  'boule.become.hint1': 'You need to Stake ',
  'boule.become.hint2': ' to become Boule Plus.',
  'boule.clickToStake': 'Click to Stake',
  'boule.stake.hint1': 'You will receive ALDPlus as staking proof and represents your Boule Plus status.',
  'boule.stake.hint2': 'You can unstake ALD and quit Boule Plus at any time.',
  'confirmUnstake.title': 'Confirm to Unstake',
  'confirmUnstake.desc': 'Unstake will quit from Boule Plus and you will note be able to get rewards from the Vault you voted.',
  'notQualified.title': 'Become Boule Plus',
  'notQualified.desc.1': 'You need to pass the quiz first.',
  'notQualified.desc.2': 'If you have passed, please make sure the address is correct.',

  // option reward UI
  'optionReward.title': 'Rewards',
  'optionReward.subtitle':
    '15% of the tokens on the Aladdin will be used to incentivize TVL growth. For every additional $100 million in TVL, 0.75% of ALD will be rewarded, and the maximum reward will be $2 billion in TVL. The incentive period is one month, starting from the creation of mining. After one month, users who pledge ALD will have the opportunity to get rewards from the growth of the TVL project on the platform.',
  'optionReward.aladdinStaking': 'Aladdin Staking',

  // staking reward UI
  'stakingReward.title': 'Aladdin Staking',

  'stakingReward.subtitle':
    'Token holders can stake ALD into the Aladdin Rewards pool and get the rewards from the listing option rewards once the target TVL is achieved.',

  'stakingReward.tableInfo': '',

  'vault.tableInfo': 'Some more info of this vault',
  'staking.tableInfo': 'Some more info of this staking pool',

  // liquidity UI
  'liquidity.title': 'Liquidity',
  'liquidity.subtitle': 'Liquidity description',
  'liquidity.tableInfo': 'Something about the project',

  // banners
  vaultBanner1: 'Get double mining rewards: {rewardSymbol}+ALD.',
  vaultBanner2: 'Step1: Deposit {deposit} in {poolName} and get {stakeSymbol}.',
  vaultBanner3: `Step2: Deposit {stakeSymbol} in Aladdin's Vault to earn {rewardSymbol} and get {vaultSymbol}.`,
  vaultBanner4: `Step3: Stake {vaultSymbol} in Aladdin's Staking pool to get ALD token rewards.`,
  // vaultBanner4: `Double mining rewards: {rewardSymbol}+ALD.`,
  stakingBanner: `Stake the assets from Aladdin's Vault to Staking pool, can get bouble mining rewards: ALD token and original asset rewards. If there is no {stakeTokenSymbol}, please deposit {stakeToken} in Aladdin vault first.`,

  // vaultBanner1: 'Deposit {deposit} in {poolName} and get {stakeSymbol}.',
  // vaultBanner2: `Users deposits {stakeSymbol} in Aladdin's vault, {stakeSymbol} will be deposited by Aladdin protocol automatically on the {poolName} platform to earn {rewardSymbol} and interest. At the same time, users can also get {vaultSymbol}.`,
  // vaultBanner3: `Deposit {vaultSymbol} in Aladdin's staking pool to get ALD token rewards.`,
  // vaultBanner4: `Double mining rewards: {rewardSymbol}+ALD.`,
  // stakingBanner: `Users stake the assets from Aladdin's vault (such as Comp, CRV, Sushi, etc.) to Staking pool, they can get bouble mining rewards: ALD token and original asset rewards. If there is no {stakeTokenSymbol}, please deposit {stakeToken} in Aladdin vault first.`,

  // lbp pagge
  'lbp.title': 'Aladdin Token Initial Launch',
  'lbp.subtitle': '2021 Apr 6th 14:00:00(UTC) - 2021 Apr 8th 14:00:00(UTC)',
  'lbp.banner1': 'Trading Pair and Initial Amount',
  'lbp.banner2': 'Start Ratio (ALD:USDC)',
  'lbp.banner3': 'End Ratio (ALD:USDC)',
  'lbp.banner4': 'Starting Price',
  'lbp.aldBalance': 'ALD Balance',
  'lbp.usdcBalance': 'USDC Balance',
  'lbp.aldWeight': 'ALD Weight',
  'lbp.usdcWeight': 'USDC Weight',
  'lbp.aldSold': 'ALD Sold',
  'lbp.fundRaised': 'Fund Raised',
  'lbp.spotPrice': 'Spot Price',
  'lbp.terminal': 'Terminal Price if No One Swap',
  'lbp.swapOnBalance': 'Swap on Balancer',
  'lbp.warning1': 'Do not rush to buy or you will get rekt.',
  'lbp.warning2': 'Watch your gas fee and slippage settings.',
  'lbp.warning3': 'Do not create a new pool on Uniswap or Balancer before the end of the LBP (watch out for impermanent loss!)',
  'lbp.warning4': 'Be ware of scams, only use the ALD address announced by Aladdin official channels.',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
