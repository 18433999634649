import config from './index'

// To reduce the invoke of the calulation of lp price, cache and update the price regularlly.
// Update process:
//   Set enableCachedLpPrice = false in config
//   Run the app and check the data in the console
//   Update the data to cachedLpPriceMapping
const cachedLpPriceMapping = {
  [config.tokens.eCRV]: 3292.42871064964814066285,
  [config.tokens.crvRenWBTC]: 48013.03739278912788614021,
  [config.tokens.threeCRV]: 1.018296,
  [config.tokens.slpETHWBTC]: 60475227874.69055,
}

export default cachedLpPriceMapping
