import React, { useEffect, useMemo, useState, useCallback } from 'react'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import Web3Modal from 'web3modal'
// @ts-ignore
import WalletConnectProvider from '@walletconnect/web3-provider'
// @ts-ignore
// import Fortmatic from "fortmatic";
// import Torus from '@toruslabs/torus-embed'
import Authereum from 'authereum'
// import { useSelector } from 'react-redux'
// import { Bitski } from "bitski";
import abi from '../utils/allabis'
import config from '../config'

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: config.INFURA_ID,
      rpc: {
        31337: 'http://47.244.201.190:8545',
      },
    },
  },
  // torus: {
  //   package: Torus,
  // },
  authereum: {
    package: Authereum,
  },
  // see if we need it
  // fortmatic: {
  //   package: Fortmatic,
  //   options: {
  //     key: process.env.REACT_APP_FORTMATIC_KEY
  //   }
  // },
  // bitski: {
  //   package: Bitski,
  //   options: {
  //     clientId: process.env.REACT_APP_BITSKI_CLIENT_ID,
  //     callbackUrl: `${window.location.href}bitski-callback.html`
  //   }
  // }
}

let web3Modal = new Web3Modal({
  // network: NETWORK_NAME,
  cacheProvider: true,
  providerOptions,
  theme:
    localStorage.getItem('app.settings.theme') && localStorage.getItem('app.settings.theme').replace(/"/g, '') === 'default'
      ? 'light'
      : { background: 'rgb(30,30,30)' },
})

export const Web3Context = React.createContext({
  web3: null,
  setWeb3: {},
  setCurrentAccount: {},
  currentAccount: null,
  currentBlock: 0,
  currentChainId: 0,
  chainBalance: 0,
  aldBalance: 0,
  connectWallet: async () => {},
  getChainBalance: async () => {},
  getErc20Balance: async () => {},
  getErc20BalanceInWei: async () => {},
  getTotalSupply: async () => {},
  resetAccount: async () => {},
  updateAldBalance: async () => {},
  updateChainBalance: async () => {},
})

function initWeb3(provider) {
  const web3 = new Web3(provider)

  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  })

  web3.eth.transactionConfirmationBlocks = 1

  return web3
}

export const Web3ContextProvider = ({ children }) => {
  const [web3, setWeb3] = useState('')
  const [provider, setProvider] = useState(null)
  const [onBlockListeners, setOnBlockListeners] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [connected, setConnected] = useState(false)
  const [currentAccount, setCurrentAccount] = useState('')
  const [currentChainId, setCurrentChainId] = useState(1)
  const [currentBlock, setCurrentBlock] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [currentNetworkId, setCurrentNetworkId] = useState(1)
  const [chainBalance, setChainBalance] = useState(0)
  const [aldBalance, setAldBalance] = useState(0)
  // const theme = useSelector(state => state.settings.theme)

  // const getAccountAssets = async () => {
  //   return false
  // }

  const resetAccount = async () => {
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close()
    }
    await web3Modal.clearCachedProvider()
    setCurrentAccount('')
  }

  const subscribeProvider = async providerNew => {
    if (!providerNew.on) {
      return
    }
    providerNew.on('close', () => {
      resetAccount()
    })
    providerNew.on('accountsChanged', async accounts => {
      setCurrentAccount(accounts[0])
    })
    providerNew.on('chainChanged', chainId => {
      setCurrentChainId(parseInt(chainId, 16))
    })
  }

  const connectWallet = useCallback(async () => {
    // console.log('[context] connectWallet starts')

    // create web3Modal again, to update the theme according to localStorage
    web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
      theme:
        localStorage.getItem('app.settings.theme') && localStorage.getItem('app.settings.theme').replace(/"/g, '') === 'default'
          ? 'light'
          : { background: 'rgb(30,30,30)' },
    })

    try {
      const providerNew = await web3Modal.connect()

      await subscribeProvider(providerNew)

      const web3New = initWeb3(providerNew)
      setWeb3(web3New)
      // console.log('[context] set web3', web3New)

      const accounts = await web3New.eth.getAccounts()

      const address = accounts[0]
      setCurrentAccount(address)

      const networkId = await web3New.eth.net.getId()
      // console.log('setCurrentNetworkId', networkId)
      setCurrentNetworkId(networkId)

      const chainId = await web3New.eth.chainId()
      setCurrentChainId(chainId)
      // console.log('setCurrentChainId', chainId)

      setProvider(providerNew)
      setConnected(true)
    } catch (err) {
      console.log('User rejected connect, set infura web3')
      const { INFURA_URL } = config
      setWeb3(new Web3(INFURA_URL))
    }
  }, [])

  useEffect(() => {
    if (connectWallet) {
      connectWallet()
    }
  }, [connectWallet])

  const getChainBalance = useCallback(async () => {
    if (!currentAccount) return 0
    const balanceRaw = await web3.eth.getBalance(currentAccount)
    const balance = new BigNumber(balanceRaw).shiftedBy(-config.chainDecimal).toFixed(4)
    return balance
  }, [web3, currentAccount])

  const getErc20Balance = useCallback(
    async (contract, decimal) => {
      if (!currentAccount) return 0
      if (!contract) return 0
      const balanceInWei = await contract.methods.balanceOf(currentAccount).call()
      const balance = new BigNumber(balanceInWei).div(new BigNumber(10).pow(decimal)).toString()
      return balance || 0
    },
    [web3, currentAccount],
  )

  const getErc20BalanceInWei = useCallback(
    async contractAddress => {
      if (web3 && currentAccount) {
        const erc20 = await new web3.eth.Contract(abi.erc20ABI, contractAddress)
        const balanceInWei = await erc20.methods.balanceOf(currentAccount).call()
        return balanceInWei || 0
      }
    },
    [web3, currentAccount],
  )

  const getTotalSupply = useCallback(
    async contractAddress => {
      if (web3) {
        const erc20 = await new web3.eth.Contract(abi.erc20ABI, contractAddress)
        const totalSupply = await erc20.methods.totalSupply().call()
        return totalSupply
      }
    },
    [web3],
  )

  const addOnBlockListener = useCallback(
    (name, listener) => {
      setOnBlockListeners(old => ({ ...old, [name]: listener }))
    },
    [setOnBlockListeners],
  )

  const removeOnBlockListener = useCallback(
    name => {
      setOnBlockListeners(old => {
        delete old[name]
        return old
      })
    },
    [setOnBlockListeners],
  )

  useEffect(() => {
    if (web3 && currentChainId === config.CHAIN_ID) {
      let subscribe = null
      const onBlock = async block => {
        // eslint-disable-next-line no-restricted-syntax
        for (const listener of Object.entries(onBlockListeners)) {
          // eslint-disable-next-line no-await-in-loop
          await listener[1]?.(block)
        }
        console.log('current block height', block.number)
        if (currentBlock !== block.number) {
          setCurrentBlock(block.number)
        }
      }
      subscribe = web3.eth.subscribe('newBlockHeaders', (error, result) => {
        onBlock(result)
      })
      return () => {
        if (subscribe) {
          subscribe.unsubscribe((error, success) => {
            if (error) {
              console.error(error)
              return
            }
            console.log(success)
          })
        }
      }
    }
  }, [provider, currentChainId, onBlockListeners])

  // Todo:: change to use wei
  const updateChainBalance = async () => {
    const balance = await getChainBalance()
    setChainBalance(`${balance} ${config.chainUnit}`)
  }

  const updateAldBalance = useCallback(async () => {
    const nativeTokenContract = new web3.eth.Contract(abi.erc20ABI, config.contracts.nativeToken)
    setAldBalance(await getErc20Balance(nativeTokenContract, 18))
  }, [web3])

  useMemo(() => {
    if (!web3 || !currentAccount) {
      return
    }
    // Todo:: change to use wei
    updateChainBalance()
    updateAldBalance()
  }, [web3, currentAccount])

  // Get current address
  useMemo(() => {
    const getAccount = async () => {
      const accounts = await web3?.eth?.getAccounts()
      if (accounts?.length > 0) {
        setCurrentAccount(accounts[0])
      }
    }
    getAccount()
  }, [web3])

  return (
    <Web3Context.Provider
      value={{
        web3,
        setWeb3,
        currentAccount,
        currentBlock,
        currentChainId,
        chainBalance,
        aldBalance,
        connectWallet,
        getChainBalance,
        getErc20Balance,
        getErc20BalanceInWei,
        getTotalSupply,
        resetAccount,
        addOnBlockListener,
        removeOnBlockListener,
        updateChainBalance,
        updateAldBalance,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}
export const Web3ContextConsumer = Web3Context.Consumer
