import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // DeFi X
  // {
  //   path: '/home',
  //   Component: lazy(() => import('pages/home')),
  //   exact: true,
  // },
  {
    path: '/vaults',
    Component: lazy(() => import('pages/vaults')),
    exact: true,
  },
  {
    path: '/stakings',
    Component: lazy(() => import('pages/stakings')),
    exact: true,
  },
  {
    path: '/staking',
    Component: lazy(() => import('pages/staking')),
    exact: true,
  },
  {
    path: '/vault',
    Component: lazy(() => import('pages/vault')),
    exact: true,
  },
  {
    path: '/liquidity',
    Component: lazy(() => import('pages/liquidity')),
    exact: true,
  },
  {
    path: '/dao',
    Component: lazy(() => import('pages/membership')),
    exact: true,
  },
  // {
  //   path: '/lbp',
  //   Component: lazy(() => import('pages/lbp')),
  //   exact: true,
  // },
  {
    path: '/boule-plus',
    Component: lazy(() => import('pages/boulePlus')),
    exact: true,
  },
  {
    path: '/reward',
    Component: lazy(() => import('pages/reward')),
    exact: true,
  },
  {
    path: '/staking-reward',
    Component: lazy(() => import('pages/staking-reward')),
    exact: true,
  },
  // {
  //   path: '/devops',
  //   Component: lazy(() => import('pages/devops')),
  //   exact: true,
  // },
  // Dashboards
  // {
  //   path: '/dashboard/alpha',
  //   Component: lazy(() => import('pages/dashboard/alpha')),
  //   exact: true,
  // },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  // {
  //   path: '/auth/500',
  //   Component: lazy(() => import('pages/auth/500')),
  //   exact: true,
  // },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/vaults" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
