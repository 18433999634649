const CHAIN_ID = 31337

const NETWORK_NAME = 'mainnet'

// used by wallet connect
const INFURA_ID = '86e777894dec49388d35b56d914732a2'
const INFURA_URL = `wss://mainnet.infura.io/ws/v3/${INFURA_ID}`

const explorerUri = 'http://www.etherscan.io/'

const enableCachedLpPrice = true

const contracts = {
  nativeToken: '0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
  wrappedNativeToken: '',
  strategyController: '0xA0C500eD25a88640F250C55Da7299c3345637F5E',

  vaultCurveRenWBTC: '0x4EE014060F4816ad294857d29C22fe62B0e9580B',
  vaultCurveSETH: '0xB17d98c36d2238Ffcb27bF797cA9967B3Cc9Aa07',
  vaultCurve3Pool: '0x5C8dC3a18761e4F22F7B8D41228970477168d9e2',
  vaultSushiETHWBTC: '0x1C7ed66abE1BA029c8EFceecfBfc4056B8C4bbfc',

  tokenMaster: '0xfF4446E9dF1c8281CE1d42610c3bC0342f93E4d7',
  daoFunding: '0xB5495A8D85EE18cfD0d2816993658D88aF08bEF4',
  daoTreasury: '0x47e9517C2c179349c6C8F4a347acbeDFCA5E99Bc',
  daoMultiStakingRewards: '0x78Dbc6888F6CCa11CAc3d4B0027557f25d15ad23',
  daoRewardDistributor: '0x0427a82Cc54509e16dCAA12802762331bd354707',
  tokenDistributor: '0x10aF3D70831A7F85192fB70CE6A7205F81294aD7',
  rewardDistributor: '',
  multiStakingRewards: '',

  curveRENPoolSwap: '0x93054188d876f558f4a66B2EF1d97d16eDf0895B',
  curveHBTCPoolSwap: '0x4CA9b3063Ec5866A4B82E437059D2C43d1be596F',
  curveSETHPoolSwap: '0xc5424b857f758e906013f3555dad202e4bdb4567',
  curve3PoolSwap: '0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7',

  lbpContract: '',
  keeper: '',
  aldplus: '',

  multiCall: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
}

const tokens = {
  eth: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  dai: '0x6b175474e89094c44da98b954eedeac495271d0f',
  weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  wbtc: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  seth: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
  renBTC: '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  aldVote: '0x6e2b4801040d5fab7D0d7700bE5903322BCf61Ce',

  crvRenWBTC: '0x49849C98ae39Fff122806C06791Fa73784FB3675',
  eCRV: '0xA3D87FffcE63B53E0d54fAa1cc983B7eB0b74A9c', // Curve.fi ETH/sETH
  hCRV: '0xb19059ebb43466c323583928285a49f558e572fd', // HBTC / WBTC
  threeCRV: '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490', // 3pool
  a3CRV: '0xFd2a8fA60Abd58Efe3EeE34dd494cD491dC14900', // aave3pool

  slpETHUSDC: '0x397ff1542f962076d0bfe58ea045ffa2d347aca0',
  slpETHWBTC: '0xceff51756c56ceffca006cd410b03ffc46dd3a58',
  slpETHDai: '0xc3d03e4f041fd4cd388c549ee2a29a9e5075882f',
  slpETHUSDT: '0x06da0fd433c1a5d7a4faa01111c044910a184553',

  unilpALDETH: '0xED6c2F053AF48Cba6cBC0958124671376f01A903',
  unilpALDUSDC: '0xaAa2bB0212Ec7190dC7142cD730173b0A788eC31',

  comp: '0xc00e94cb662c3520282e6f5717214004a7f26888',
  crv: '0xD533a949740bb3306d119CC777fa900bA034cd52',
  sushi: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
}

const chainUnit = 'ETH'
const chainDecimal = 18

export default {
  INFURA_ID,
  INFURA_URL,
  CHAIN_ID,
  NETWORK_NAME,
  explorerUri,
  tokens,
  contracts,
  chainUnit,
  chainDecimal,
  enableCachedLpPrice,
}
